import React, { useEffect, useMemo, useState } from "react"
import { toDay, toHours } from "../utilities/formatting"
import { REACT_ON_POST } from "../gql"
import { useMutation } from "@apollo/react-hooks"
import CommentList from "./comment-list"
import { useTranslation } from "react-i18next"
import { Trans } from "react-i18next"
import useLocalizedContent from "../utilities/useLocalicationContent"
import PostLikesAndImages from "./post-likes-images"

const Post = ({
  user,
  date,
  content,
  localization,
  image,
  userImage,
  likes,
  id,
  userData,
  comments,
  refetchLoadedPosts,
}) => {
  const day = toDay(date)
  const hours = toHours(date)
  const [isLike, setIsLike] = useState(false)
  const [isDefaultLike, setIsDefaultLike] = useState(false)
  const [reactOnPost] = useMutation(REACT_ON_POST)
  const [isOpenComment, setIsOpenComment] = useState(false)
  const [commentList, setCommentList] = useState([...comments])
  const { t, i18n } = useTranslation()
  const getContent = useLocalizedContent(content, i18n)

  const onCommentAddition = comment => {
    const { content, localization } = comment
    setCommentList(prev => [
      ...prev,
      {
        content,
        localization,
        user: {
          avatarNumber: userData.me.avatarNumber,
          name: userData.me.name,
          teamName: userData.me.teamName,
        },
      },
    ])
  }

  const isLikeExist = useMemo(() => {
    if (likes && likes.length && userData && userData.me) {
      const userEmail = userData.me.email
      const findUser = (likes, userEmail) =>
        likes.some(eachLike => eachLike.email === userEmail)
      return findUser(likes, userEmail)
    }
    return false
  }, [likes, userData])

  useEffect(() => {
    setIsLike(isLikeExist)
    setIsDefaultLike(isLikeExist)
  }, [isLikeExist])

  const handleOnLike = () => {
    reactOnPost({
      variables: { postId: id },
      update: () => {
        setIsLike(!isLike)
        refetchLoadedPosts()
      },
    })
  }

  let likeCount = likes && likes.length ? likes.length : 0
  likeCount = likeCount + (isDefaultLike && !isLike ? -1 : 0)
  likeCount = likeCount + (!isDefaultLike && isLike ? 1 : 0)
  return (
    <div className="post">
      <div className="post__content">
        <div className="post__header">
          <div className="post__header__userimage">
            <img
              src={
                user && user.teamImage && user.teamImage.path
                  ? user.teamImage.path
                  : userImage
              }
              alt=""
            />
          </div>
          <div className="post__header__wrapper">
            <p className="post__header__username">
              {user.teamName ? user.teamName : user.name}
            </p>
            <p className="post__header__date">
              {day} <Trans i18nKey="post.at_text" /> {hours}{" "}
            </p>
          </div>
        </div>
        <p className="post__text">
          {content.trim().length > 0 && (
            <>
              (translated) - {getContent({ localization, content })} <br />
              (Original) - {content}
            </>
          )}
        </p>
      </div>
      {image && <img src={image.path} className="post__image" alt=""></img>}
      <div className="feed-action-container">
        <div
          className={`${!isLike ? "action-box" : "action-box-liked"}`}
          onClick={handleOnLike}
        >
          <p>
            {likeCount ? likeCount : ""} {`${t("feed.like")}`}
          </p>
        </div>
        <div
          className={`${!isOpenComment ? "action-box" : "action-box-liked"}`}
          onClick={() => setIsOpenComment(prev => !prev)}
        >
          <p>{`${commentList.length} ${t("feed.comments")}`}</p>
        </div>
      </div>
      <PostLikesAndImages likes={likes} />
      {isOpenComment && (
        <CommentList
          postId={id}
          comments={commentList}
          onCommentAddition={onCommentAddition}
        />
      )}
    </div>
  )
}

export default Post
