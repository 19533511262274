import { useState } from 'react';
import { GET_PRESIGNED_URL } from '../gql/upload';
import { useMutation } from '@apollo/react-hooks';
const uuid = require('uuid'); 
const useS3 = () => {
    const [uploadingImage, setUploadingImage] = useState();
    const [s3SignedUrl, setS3SignedUrl] = useState(null);
    const [getPreSignedUrl, { loading: loadinggetPreSignedUrl }] = useMutation(GET_PRESIGNED_URL)

    const generateSignedUrl = async (fileName,file) => {
        try {
            const responseData = await getPreSignedUrl({
                variables: {
                    fileName: fileName,
                    fileType: file.type
                }
            })

            return responseData.data.getPreSignedUrl;
        } catch (error) {
            console.error('Error generating signed URL:', error);
        }
    };

    const uploadToS3 = async (file) => {
        try {
            const fileName= uuid.v4();
            let signedUrl = s3SignedUrl;
            if (!s3SignedUrl) {
                setUploadingImage(true)
                const generatedSignedUrl = await generateSignedUrl(fileName,file);
                signedUrl = generatedSignedUrl;
                setS3SignedUrl(generatedSignedUrl)
       
            }
            const options = {
                headers: {
                    "Content-Type": file.type
                }
            }
            const uploadData = await fetch(signedUrl, {
                method: 'PUT',
                body: file,
                ...options,
            });
            const responseData = {
                path : uploadData.url,
                mimetype : file.type,
                name : fileName
            }
            setUploadingImage(false);
            return responseData;
        } catch (error) {
            setUploadingImage(false);
            console.error('Error uploading file to S3:', error);
        }
    };

    return { uploadToS3, loading: uploadingImage || loadinggetPreSignedUrl };
};

export default useS3;
