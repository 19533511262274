import React, { useState } from "react"
import Layout from "../components/layout"
import CreatePost from "../components/create-post"
import PostList from "../components/post-list"
import Throbber from "../components/throbber"
import { useInView } from "react-intersection-observer"
import { useQuery, useMutation } from "@apollo/react-hooks"
import { GET_FEED, CREATE_POST, ME } from "../gql"
import Filters from "../components/filters"
import {
  FEED_FILTER,
  FEEDS_FILTER_OPTIONS,
  GENERAL_DROPDOWN_OPTION,
} from "../constants"
import TETorch from "./../assets/brands/te/te-torch.png"
import { Trans, useTranslation } from "react-i18next"
import { Journey, USER_ID_AND_COMPANY, USER_JOURNEYS_ACTIVITIES } from "../gql"
import { getAllJourneysDataSorted } from "../utilities/helpers"

const DISABLE_ACTIVE_JOURNEY = false

const Feed = ({ location }) => {
  const { nodes: allJourneysWithActivities } = Journey()
  const [skipPosts, setSkipPosts] = React.useState(0)
  const [ref, loadMorePosts] = useInView()
  const [filter, setFilter] = useState(FEED_FILTER.GLOBAL)
  const { data: userData, loading: userDataLoading } = useQuery(ME)
  const { t } = useTranslation()
  const limit = 20
  const { data: myData, dataLoading } = useQuery(USER_JOURNEYS_ACTIVITIES)
  const { data: myCompanyData } = useQuery(USER_ID_AND_COMPANY)
  const [journeysList, setJourneysList] = useState([])
  const [loadingJourneys, setLoadingJourneys] = useState(true)
  const [selectedJourneyFilter, setSelectedJourneyFilter] = useState(null)
  const [selectedActivityFilter, setSelectedActivityFilter] = useState(null)
  const {
    data: feedData,
    loading,
    fetchMore,
    refetch: refetchFeeds,
  } = useQuery(GET_FEED, {
    variables: {
      pagination: {
        skip: 0,
        limit: limit,
      },
      filterBy: filter,
      // Conditionally add contentfulId if selectedJourneyFilter has a contentful_id
      ...(selectedJourneyFilter && selectedJourneyFilter.contentful_id
        ? { contentfulId: selectedJourneyFilter.contentful_id }
        : {}),
      // Conditionally add activityContentfulId if selectedActivityFilter has a contentful_id
      ...(selectedActivityFilter && selectedActivityFilter.contentful_id
        ? { activityContentfulId: selectedActivityFilter.contentful_id }
        : {}),
    },
  })

  const [post, { loading: submittingPost }] = useMutation(CREATE_POST, {
    refetchQueries: [
      {
        query: GET_FEED,
        variables: {
          pagination: {
            skip: 0,
            limit: limit,
          },
        },
      },
    ],
  })

  const getActiveJourneys = allJourneys => {
    // Get all the active journeys

    const activeJourneys = DISABLE_ACTIVE_JOURNEY
      ? allJourneys.filter(journey => {
          const myJourney = myData.me.journeys.find(myJourneys => {
            return myJourneys.contentfulId === journey.contentful_id
          })
          if (myJourney) return myJourney.active
          return false
        })
      : []

    // Get the company specific activities (if there is any)
    const companySpecific = allJourneys.filter(journey => {
      if (journey.companySpecific) {
        return (
          journey.companySpecific.contentful_id === myCompanyData.me.company
        )
      }
      return false
    })

    // Merge the recommended journeys with the company specific and active journeys.
    // This is because the recommended and company specific journeys should always be under "Your Journeys"
    const mergedJourneys = [
      // ...recommendedJourneys,
      ...companySpecific,
      ...activeJourneys,
    ]
    // Run through a Set to remove duplicates (if a recommended journey is also active)
    const mergedJourneysSet = [...new Set(mergedJourneys)]

    const returnedDataSets = getAllJourneysDataSorted(mergedJourneysSet)
    returnedDataSets.unshift(GENERAL_DROPDOWN_OPTION)

    setLoadingJourneys(false)
    setJourneysList(returnedDataSets)
  }

  React.useEffect(() => {
    if (allJourneysWithActivities && myData && myCompanyData) {
      getActiveJourneys(allJourneysWithActivities)
    }
  }, [allJourneysWithActivities, myData, myCompanyData])

  React.useEffect(() => {
    // If the div at the bottom of the feed is in view, and loading of the first batch of posts have finished, fetch more posts.
    if (loadMorePosts && !loading) {
      getMorePosts()
    }
  }, [loadMorePosts])

  const handleOnRefetch = () => {
    refetchFeeds()
  }

  const getMorePosts = () => {
    fetchMore({
      variables: {
        pagination: {
          skip: skipPosts + limit,
          limit: limit,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        setSkipPosts(skipPosts + limit)
        return {
          feed: {
            posts: [...prev.feed.posts, ...fetchMoreResult.feed.posts],
            __typename: "Feed",
          },
        }
      },
    })
  }

  // this will re-fetch all the posts again, so that when we like any post we stay on the same post
  // regardless of how many times we have used fetch more posts.
  const refetchLoadedPosts = () => {
    fetchMore({
      variables: {
        pagination: {
          skip: 0,
          limit: skipPosts + limit,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        setSkipPosts(skipPosts + limit)
        return {
          feed: {
            posts: fetchMoreResult.feed.posts,
            __typename: "Feed",
          },
        }
      },
    })
  }

  return (
    <Layout path={location.pathname}>
      <div className="row">
        <div className="feed-container">
          <div className="flex-center">
            <img src={TETorch} alt="te-torch" className="torch-image" />
            <h1 className="big-title uppercase center no-margin--bottom">
              <Trans i18nKey="feed.title" />
            </h1>
          </div>
          <CreatePost
            createPost={post}
            isSubmitting={submittingPost}
            handleOnRefetch={handleOnRefetch}
            journeysList={journeysList}
            loading={loadingJourneys}
          />
          <div className="feed-container__horizontal-rule">&nbsp;</div>
          <Filters
            filterOptions={FEEDS_FILTER_OPTIONS}
            filter={filter}
            setFilter={setFilter}
            page="feed"
            loading={loading}
          />
          {loading || userDataLoading || dataLoading ? (
            <Throbber relative loadingText={t("feed.loading_translation")} />
          ) : (
            <></>
          )}
          {!loading &&
            !userDataLoading &&
            !dataLoading &&
            feedData &&
            feedData.feed && (
              <PostList
                userData={userData}
                posts={feedData.feed.posts}
                refetchLoadedPosts={refetchLoadedPosts}
              />
            )}
          <div ref={ref}></div>
        </div>
      </div>
    </Layout>
  )
}

export default Feed
