import React from "react"
import { Trans, useTranslation } from "react-i18next"

const Filters = ({ filter, setFilter, filterOptions, page, loading }) => {
  const { t: translate } = useTranslation()

  return (
    <>
      {page === "feed" ? (
        <h3 className="create-post__title">
          <Trans i18nKey="feed.feed_title" />
        </h3>
      ) : null}
      <div className="filter-container">
        {filterOptions.map(eachFilter => {
          const { translationKey, value, bgColor, boxShadow } = eachFilter
          return (
            <button
              className={`filter__button ${
                filter === value ? "filter__selected" : ""
              }${loading ? "disable-state" : ""}`}
              onClick={() => setFilter(value)}
              disabled={loading}
              key={value}
              style={{
                backgroundColor: bgColor,
                boxShadow: filter === value ? boxShadow : "",
              }}
            >
              {translate(translationKey)}
            </button>
          )
        })}
      </div>
    </>
  )
}

export default Filters
