import React, { useState } from "react"
import DefaultAvatar from "../assets/images/group.png"
import { Trans, useTranslation } from "react-i18next"
import { useMutation } from "@apollo/react-hooks"
import { COMMENT_ON_POST } from "../gql"
import useLocalizedContent from "../utilities/useLocalicationContent"

const CommentList = ({ comments, onCommentAddition, postId }) => {
  const { t, i18n } = useTranslation()
  const [comment, setComment] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [commentOnPost] = useMutation(COMMENT_ON_POST)
  const getContent = useLocalizedContent(comments, i18n)

  const onSubmitComment = async () => {
    try {
      setIsLoading(true)
      commentOnPost({
        variables: { postId, content: comment },
        update: (_, { data }) => {
          setIsLoading(false)
          setComment("")
          onCommentAddition(data.comment)
        },
      })
    } catch (e) {
      setIsLoading(false)
      console.error("ERROR WHILE POSTING COMMENT", e)
    }
  }

  return (
    <div className="action-section-comment-container">
      <div className="action-comment-list">
        {comments.map((comment, index) => {
          if (comment && comment.user && comment.user.avatarNumber) {
            return (
              <Comment
                translatedContent={getContent(comment)}
                key={index}
                content={comment.content}
                userName={comment.user.name}
                teamName={comment.user.teamName}
                userImage={
                  comment.user.teamImage && comment.user.teamImage.path
                    ? comment.user.teamImage.path
                    : DefaultAvatar
                }
              />
            )
          } else {
            return (
              <Comment
                key={index}
                translatedContent={getContent(comment)}
                content={comment.content}
                userName={comment.user.name}
                teamName={comment.user.teamName}
                userImage={
                  comment.user &&
                  comment.user.teamImage &&
                  comment.user.teamImage.path
                    ? comment.user.teamImage.path
                    : DefaultAvatar
                }
              />
            )
          }
        })}
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="create-post__input-wrapper">
          <textarea
            value={comment}
            onChange={e => setComment(e.target.value)}
            className="create-post__input"
            placeholder={t("feed.comments")}
          />
        </div>
        <div style={{ textAlign: "right", padding: 10 }}>
          <button
            disabled={!comment || isLoading}
            onClick={onSubmitComment}
            className="button button--small margin--left-10"
          >
            <Trans i18nKey={"feed.save"} />
          </button>
        </div>
      </div>
    </div>
  )
}

const Comment = ({
  content,
  translatedContent,
  userImage,
  userName,
  teamName,
}) => {
  return (
    <div className="action-comment-container">
      <div className="action-comment-avatar">
        <img src={userImage ? userImage : DefaultAvatar} alt="Avatar" />
      </div>
      <div className="action-comment-text">
        <p className="heading-text">{teamName ? teamName : userName}</p>
        <p className="content-text">
          {content.trim().length > 0 && (
            <>
              (translated) - {translatedContent} <br /> (Orignal) - {content}{" "}
            </>
          )}
        </p>
      </div>
    </div>
  )
}

export default CommentList
