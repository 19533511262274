import React from "react"
import Post from "./post"

import defaultAvatar from "../assets/images/group.png"

const PostList = ({ posts, userData, refetchLoadedPosts }) => {
  return (
    <div className="margin--top-100">
      {posts
        .map(post => {
          return {
            ...post,
            date: new Date(post.date),
          }
        })
        .sort((a, b) => {
          return b.date - a.date
        })
        .map((post, index) => (
          <Post
            key={post.id}
            userData={userData}
            {...post}
            userImage={defaultAvatar} // The number is from 1-100, in case we want to support more icons in the future. Currently there is only 50, so we do % 50.
            refetchLoadedPosts={refetchLoadedPosts}
          />
        ))}
    </div>
  )
}

export default PostList
