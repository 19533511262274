import { useCallback } from "react"
import { SCHEMA_LANGUAGES } from "../constants"

const useLocalizedContent = (items, i18n) => {
  const getContent = useCallback(
    eachItem => {
      let itemContent = ""
      if (eachItem && eachItem.localization) {
        const languageCode = SCHEMA_LANGUAGES[i18n.language]

        if (eachItem.localization[languageCode]) {
          itemContent = eachItem.localization[languageCode].content
        }
      }

      return itemContent ? itemContent : eachItem.content
    },
    [items, i18n.language]
  )

  return getContent
}

export default useLocalizedContent
