import React, { useEffect, useState } from "react"
import DefaultAvatar from "../assets/images/group.png"

// Utility function to safely access nested properties
const getNestedProperty = (obj, path) => {
  return path.reduce((acc, part) => (acc && acc[part] ? acc[part] : null), obj)
}

const PostLikesAndImages = ({ likes = [] }) => {
  const [likeCount, setLikeCount] = useState(0)

  useEffect(() => {
    if (likes.length > 1) {
      setLikeCount(likes.length - 1)
    }
  }, [likes])

  const firstLike = likes[0]
  const secondLike = likes[1]

  const firstImageSrc =
    getNestedProperty(firstLike, ["teamImage", "path"]) || DefaultAvatar
  const secondImageSrc = secondLike
    ? getNestedProperty(secondLike, ["teamImage", "path"]) || DefaultAvatar
    : null

  return likes.length ? (
    <div className="likes_and_images_con">
      <div className="images_con">
        {firstLike ? (
          <img
            className="avatar avatar_1"
            src={firstImageSrc}
            alt={firstLike.teamName ? firstLike.teamName : firstLike.name}
          />
        ) : null}
        {secondLike ? (
          <img
            className="avatar avatar_2"
            src={secondImageSrc}
            alt={secondLike.teamName ? secondLike.teamName : secondLike.name}
          />
        ) : null}
      </div>
      <div className="text">
        liked by{" "}
        {firstLike
          ? `${firstLike.teamName ? firstLike.teamName : firstLike.name} `
          : null}
        {likeCount > 0 ? `and ${likeCount} other` : null}
        {likeCount > 1 ? "s" : ""}
      </div>
    </div>
  ) : null
}

export default PostLikesAndImages
