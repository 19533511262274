import React, { useState, useEffect } from "react"
import useS3 from "../hooks/useS3"
import { Trans, useTranslation } from "react-i18next"
import Throbber from "./throbber"
import useCurrentLang from "../hooks/useCurrentLang"

import {
  handleFeedsSelectJourneyChange,
  handleFeedSelectActivityChange,
} from "../utilities/helpers"

const CreatePost = ({
  isSubmitting,
  createPost,
  handleOnRefetch,
  journeysList,
  loading,
}) => {
  const { code: langCode } = useCurrentLang()
  const [content, setContent] = React.useState("")
  const { uploadToS3, loading: loadingMediaUpload } = useS3()
  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedJourney, setSelectedJourney] = useState(null)
  const [selectedActivity, setSelectedActivity] = useState(null)
  const { t } = useTranslation()

  const clearInput = () => {
    setContent("")
    clearImage()
  }

  const clearImage = () => {
    setSelectedFile(null)
  }

  const selectImage = async e => {
    setSelectedFile(e.target.files)
  }

  const submitPost = async e => {
    e.preventDefault()

    let variables = {}
    if (content) {
      variables = {
        content,
      }
    } else {
      variables = {
        content: " ",
      }
    }

    if (selectedFile) {
      const file = selectedFile[0]
      const s3UploadResponse = await uploadToS3(file)
      variables = {
        ...variables,
        image: s3UploadResponse,
      }
    }
    // Check if selectedJourney exists and has a contentful_id, then add it to variables
    if (selectedJourney && selectedJourney.contentful_id) {
      variables.contentfulId = selectedJourney.contentful_id
    }

    // Check if selectedActivity exists and has a contentful_id, then add it to variables
    if (selectedActivity && selectedActivity.contentful_id) {
      variables.activityContentfulId = selectedActivity.contentful_id
    }
    createPost({
      variables: variables,
      update: () => {
        handleOnRefetch()
        clearInput()
      },
    })

    clearInput()
  }

  useEffect(() => {
    if (journeysList && journeysList.length) {
      setSelectedJourney(journeysList[0])
      if (
        journeysList[0] &&
        journeysList[0].activities &&
        journeysList[0].activities.length
      ) {
        setSelectedActivity(journeysList[0].activities[0])
      }
    }
  }, [journeysList])
  return (
    <div className="create-post">
      <h3 className="create-post__title">
        <Trans i18nKey="feed.add_post_title" />
      </h3>
      <div className="create-post__input-wrapper">
        <textarea
          value={content}
          onChange={e => setContent(e.target.value)}
          className="create-post__input"
          placeholder={t("feed.message_placeholder")}
        />
        {selectedFile && (
          <div className="create-post__image-preview">
            <div
              onClick={() => clearImage()}
              className="create-post__remove-image"
            >
              <svg width="12" height="11" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.577.723L6.034 5.18 10.49.723l.42.42L6.454 5.6l4.457 4.457-.42.42L6.034 6.02l-4.457 4.457-.42-.42L5.614 5.6 1.157 1.143z"
                  fill="#000"
                  fillRule="nonzero"
                  stroke="#000"
                />
              </svg>
            </div>
            <img
              className="post__image"
              src={selectedFile ? URL.createObjectURL(selectedFile[0]) : ""}
              alt=""
            />
          </div>
        )}
        {/* <div className="post__image" ref={canvasRef}></div> */}
      </div>

      {loading ? (
        <div className="create-post__image-preview">
          <Throbber relative />
        </div>
      ) : (
        <></>
      )}
      {!loading ? (
        <div className="create-post__dropdowns_wrapper">
          <div className="create-post__dropdowns_wrapper__dd_wrapper">
            <label className="create-post__dropdowns_wrapper__dd_wrapper__labels">
              <Trans i18nKey={"feed.connect_journey"} />
            </label>
            <select
              className="create-post__dropdowns_wrapper__dd_wrapper__select_dds"
              value={
                selectedJourney && selectedJourney.contentful_id
                  ? selectedJourney.contentful_id
                  : ""
              }
              onChange={e =>
                handleFeedsSelectJourneyChange(
                  e,
                  journeysList,
                  setSelectedJourney,
                  setSelectedActivity
                )
              }
            >
              {journeysList && journeysList.length
                ? journeysList.map(journey => (
                    <option
                      value={journey.contentful_id}
                      key={journey.contentful_id}
                    >
                      {journey[
                        `title_${langCode === "zh" ? "hk" : langCode}`
                      ] || journey.title}{" "}
                      {journey.subtitle &&
                      journey.subtitle.subtitle &&
                      journey.subtitle.subtitle.includes("Week")
                        ? ` - ${
                            journey[
                              `subtitle_${langCode === "zh" ? "hk" : langCode}`
                            ]
                              ? journey[
                                  `subtitle_${
                                    langCode === "zh" ? "hk" : langCode
                                  }`
                                ][
                                  `subtitle_${
                                    langCode === "zh" ? "hk" : langCode
                                  }`
                                ]
                              : journey.subtitle.subtitle
                          }`
                        : ""}
                    </option>
                  ))
                : null}
            </select>
          </div>
          {selectedJourney && selectedJourney.contentful_id ? (
            <div className="create-post__dropdowns_wrapper__dd_wrapper">
              <label className="create-post__dropdowns_wrapper__dd_wrapper__labels">
                <Trans i18nKey={"feed.connect_activity"} />
              </label>
              <select
                className="create-post__dropdowns_wrapper__dd_wrapper__select_dds"
                value={
                  selectedActivity && selectedActivity.contentful_id
                    ? selectedActivity.contentful_id
                    : ""
                }
                disabled={
                  !selectedJourney ||
                  (selectedJourney &&
                    selectedJourney.activities &&
                    selectedJourney.activities.length === 0)
                }
                onChange={e =>
                  handleFeedSelectActivityChange(
                    e,
                    selectedJourney,
                    setSelectedActivity
                  )
                }
              >
                {selectedJourney &&
                selectedJourney.activities &&
                selectedJourney.activities.length
                  ? selectedJourney.activities.map(activity => (
                      <option
                        value={activity.contentful_id}
                        key={activity.contentful_id}
                      >
                        {activity[
                          `title_${langCode === "zh" ? "hk" : langCode}`
                        ] || activity.title}
                      </option>
                    ))
                  : null}
              </select>
              {selectedJourney &&
              selectedJourney.activities &&
              selectedJourney.activities.length === 0 ? (
                <span className="create-post__dropdowns_wrapper__dd_wrapper__err">
                  <Trans i18nKey={"feed.connect_activity_err"} />
                </span>
              ) : null}
            </div>
          ) : (
            <div className="create-post__dropdowns_wrapper__dd_wrapper">
              &nbsp;
            </div>
          )}
        </div>
      ) : null}

      <div className="create-post__button-wrapper">
        <div>
          <label className="button button--small">
            <div className="create-post__add-image-wrapper">
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M5 0V10M0 5H10" stroke="black" strokeWidth="2" />
              </svg>

              <span className="margin--left-6">
                <Trans i18nKey={"feed.add_attachment"} />
              </span>
            </div>

            <input
              type="file"
              name="image"
              accept=".png, .jpeg, .jpg"
              onChange={selectImage}
              onClick={e => (e.target.value = null)}
            />
          </label>
        </div>

        <div>
          <button
            type="button"
            onClick={clearInput}
            className="button button--small"
          >
            <Trans i18nKey={"feed.cancel"} />
          </button>
          <button
            disabled={
              isSubmitting ||
              loadingMediaUpload ||
              (!selectedFile && !content) ||
              loading
            } // Disable the submit button IF: 1. we are currently submitting a post or 2. the post has no image AND no content.
            onClick={submitPost}
            type="submit"
            className="button button--small margin--left-10"
          >
            <Trans i18nKey={"feed.submit"} />
          </button>
        </div>
      </div>
    </div>
  )
}

export default CreatePost
