import gql from "graphql-tag"

const GET_PRESIGNED_URL = gql`
  mutation getPreSignedUrl($fileName: String!, $fileType :String!) {
    getPreSignedUrl(fileName: $fileName, fileType: $fileType) 
  }`


  export {
    GET_PRESIGNED_URL
  }
  