export const toDay = dateObject => {
  const options = {
    month: "long",
    day: "numeric",
  }

  const day = dateObject.toLocaleDateString("en-GB", options)
  return day
}

export const toHours = dateObject => {
  const options = {
    hour: "2-digit",
    minute: "2-digit",
  }

  const hours = `${dateObject.toLocaleTimeString("en-GB", options)}`
  return hours
}

export const generateRankText = (rank) => {
  let suffix = 'th'
  if (!(10 <= rank % 100 && rank % 100 <= 20)) {
    suffix = { 1: 'st', 2: 'nd', 3: 'rd' }[rank % 10] || suffix;
  }
  return `${rank}${suffix}`;
}
